export default {
	mounted()
	{
		if (this.$store.state.timerForReloadOnDayEnd)
			return;

		let currentDate = new Date(),
			endDayDate = new Date(currentDate.getTime());

		endDayDate.setHours(23);
		endDayDate.setMinutes(59);
		endDayDate.setSeconds(59);

		let secondsToDayEnd = (endDayDate.getTime() - currentDate.getTime());

		this.$store.state.timerForReloadOnDayEnd = setTimeout(() => window.location.reload(), secondsToDayEnd);
	}
}