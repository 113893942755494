<template>
	<main class="index-page">
		<section class="index-main">
			<div class="container-base landing">
				<MainSlider
					v-if="renderSlider"
					class="index-page__slider"
				/>
				<SearchMain class="index-page__search"/>
			</div>
		</section>
		<ConfirmationPopup
			:visible="confirmInvitePopup"
			:title="inviteCartTitle"
			@closePopup="toggleConfirmInvitePopup"
		>
			<template #content>
				<CartOrderInfo
					:title="inviteCartInfo?.address"
					:subtitle="convertCartOrderInfoToText({
					timeRange: inviteCartInfo?.time_range,
					day: inviteCartInfo?.day,
					plannedDeliveryTime: inviteCartInfo?.planned_delivery_time
				})"
				/>
			</template>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="toggleConfirmInvitePopup"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="checkInviteUser"
				>Присоединиться
				</button>
			</template>
		</ConfirmationPopup>
	</main>
</template>

<script>
import Updater from '@/mixins/updater.js'
import {useYandexMetrika} from "@/composables";
import search from "@/mixins/search";
import {loadYmap} from "vue-yandex-maps";
import {notification} from "@/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {convertCartOrderInfoToText, errorOutput, formatDate, redirectBasedOnTimeRange} from "@/helpers";

export default {
	name: 'IndexPage',
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	data()
	{
		return {
			image: this.$store.state.currentCityImg,
			renderSlider: true,

			confirmInvitePopup: false,
			inviteCartInfo: null
		}
	},
	components:
		{
			SearchMain: () => import('@/components/form/SearchMain.vue'),
			FoodTypesSlider: () => import('@/components/block/FoodTypesSlider.vue'),
			MainSlider: () => import('@/components/block/MainPageSlider.vue'),
			CartOrderInfo: () => import("ui-kit/CartOrderInfo.vue"),
			ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup.vue"),
		},
	computed:
		{
			...mapGetters({
				user: 'getUser',
				currentCityId: "currentCityId",
			}),
			inviteCartTitle()
			{
				const owner = this.inviteCartInfo?.owner || "Пользователь";
				const cartName = this.inviteCartInfo?.cart_name || "Коллективную корзину";

				return `${owner} пригласил(ла) Вас в «${cartName}»`;
			}
		},
	methods:
		{
			...mapActions({
				getInviteData: 'getInviteData',
				getTokenAfterInvite: 'getTokenAfterInvite',
				getBasketTypes: "getBasketTypes",
				changeCity: "changeCity",
			}),
			...mapMutations({
				openPhoneAuthPopup: 'openPhoneAuthPopup',
				setCurrentDate: 'setCurrentDate',
			}),
			convertCartOrderInfoToText,
			async fetchInviteData({type, info})
			{
				try
				{
					const {data} = await this.getInviteData({type, info})

					if (!data.success) return errorOutput(data.errors);

					this.inviteCartInfo = data.cart;
					this.toggleConfirmInvitePopup();
				} catch (e)
				{
					throw new Error(e)
				}
			},
			toggleConfirmInvitePopup()
			{
				this.confirmInvitePopup = !this.confirmInvitePopup
			},
			async checkInviteUser()
			{
				try
				{
					const {
						success,
						cart,
						errors,
						repeat
					} = await this.getTokenAfterInvite(this.$route.query.token)

					if (!this.user) return this.openPhoneAuthPopup();

					if (!success) return errorOutput(errors);

					return this.setMainData(cart, repeat);
				} catch (e)
				{
					return notification({title: 'Произошла ошибка при присоединении к корзине', type: 'error'});
				} finally
				{
					this.toggleConfirmInvitePopup();
				}
			},
			async setMainData(data, repeat)
			{
				const date = formatDate(data.day);
				this.setCurrentDate(date);
				await this.$cookie.set("day", date, 12);

				await this.$cookie.set('basketId', data.id);

				await this.$store.dispatch("fetchCurrentRestaurant", data.restaurantId);

				const basketCityId = Object.keys(data.city)?.[0] || '';
				if (this.currentCityId !== basketCityId && basketCityId) await this.changeCity({UF_XML_ID: basketCityId});

				this.$store.commit("map/setDeliveryAddress", data.address);

				await loadYmap({
					apiKey: process.env.VUE_APP_YA_API_KEY,
					lang: 'ru_RU',
					coordorder: 'latlong',
					version: '2.1'
				});

				await this.setRestaurants({incommingRestaurants: [{id: data.restaurantId}]});

				await this.getBasketTypes();

				redirectBasedOnTimeRange(data.timeRange || 'lunch');

				const title = repeat ? "Вы уже присоединены к этой корзине" : "Вы успешно присоединились к корзине";
				notification({title});
			},
			updateRender()
			{
				this.renderSlider = false;
				setTimeout(() => this.renderSlider = true, 150);

				setTimeout(() =>
				{
					if (window.innerWidth < 567)
					{
						document.documentElement.style.backgroundColor = '#40AE49';
						document.body.style.position = 'fixed';
						document.body.style.top = '-1px';
						document.documentElement.style.height = '1000px';
					} else
					{
						document.body.style.position = 'unset';
						document.body.style.top = 'unset';
						document.documentElement.style.backgroundColor = 'unset';
						document.documentElement.style.height = 'unset';
					}
				}, 150)
			},
		},
	watch:
		{
			"$store.state.currentRestarauntId"()
			{
				this.$store.dispatch("restaurant/getRestDeliveryTime");
			},
		},
	mixins: [Updater, search],

	mounted()
	{
		//Удаляем выбранную корзину, как только попали на главную
		this.$cookie.delete('basketId');

		this.yandexMetrika();

		if (this.$route.query.token)
			this.fetchInviteData({type: 'token', info: this.$route.query.token});
		else if (this.$route.query.cartType)
			this.fetchInviteData({type: 'id', info: this.$route.query.cartType});

		if (window.innerWidth < 567)
		{
			document.documentElement.style.backgroundColor = '#40AE49';
			document.body.style.position = 'fixed';
			document.body.style.top = '-1px';
			document.documentElement.style.height = '1000px';
		}

		window.addEventListener('orientationchange', this.updateRender);
	},

	beforeDestroy()
	{
		document.body.style.position = 'unset';
		document.body.style.top = 'unset';
		document.documentElement.style.backgroundColor = 'unset';
		document.documentElement.style.height = 'unset';

		window.removeEventListener('orientationchange', this.updateRender);
	}
}
</script>

<style lang="scss">
.index-page
{
	max-width: 100vw;
	min-height: 100%;
	background: radial-gradient(50% 50% at 50% 50%, #a4ff79 0, rgba(64, 174, 73, 0) 100%) #40ae49;
	padding-bottom: 160px;
	touch-action: unset;

	@media (max-width: 567px)
	{
		position: relative;
		max-height: 100%;
		overflow: hidden;
		-webkit-overflow-scrolling: initial;
		touch-action: none;
		padding-bottom: 0;

		&__search
		{
			margin-top: -16px;
		}
	}
}
</style>
